/* Ensure the body takes full height */
/* Ensure the body takes full height and uses a flex container */
body {
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Ensure main content grows to fill available space */
main {
  flex: 1;
}

/* Footer styling */
footer {
  background-color: #f8f9fa;
  border-top: 1px solid #ddd;
  text-align: center;
  padding: 1rem 0;
}

.ws-img-clickable {
  cursor: pointer;
}

.offcanvas {
  z-index: 1070 !important; /* Ensure it's above the modal */
}

.offcanvas-backdrop {
  z-index: 1060 !important; /* Ensure backdrop is clickable */
}